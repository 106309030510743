<template src="./Camera.html"></template>


<script>

import { QrcodeStream } from 'vue-qrcode-reader'

export default {

  name: "Camera",

  components: {QrcodeStream},

  data: () => ({

    camera: 'auto',

    result: null,
    showScanConfirmation: false,

    torchActive: false,
    torchNotSupported: false,

    noRearCamera: false,
    noFrontCamera: false

  }),

  created() {

  },

  computed:{
    torch_icon() {
      if (this.torchActive)
        return 'mdi-flashlight'
      else
        return 'mdi-flashlight-off'
    },
  },


  methods: {

    switchCamera (i) {
      switch (i) {
        case 0:
          this.camera = 'auto'
          break;
        case 1:
          this.camera = 'rear'
          break
        case 2:
          this.camera = 'front'
          break
      }
    },



    async onInit (promise) {
      try {

        const { capabilities } = await promise
        console.log(capabilities);

        this.torchNotSupported = !capabilities.torch

      }
      catch (e) {
        console.error(e)
        const triedFrontCamera = this.camera === 'front'
        const triedRearCamera = this.camera === 'rear'

        const cameraMissingError = e.name === 'OverconstrainedError'

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true
        }

      }
      finally {
        this.showScanConfirmation = this.camera === "off"
      }
    },

    async onDecode (content) {

      console.log(content);

      this.result = content

      this.pause()
      await this.timeout(500)
      this.unpause()

    },

    unpause () {
      this.camera = 'auto'
    },

    pause () {
      this.camera = 'off'
    },

    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },



    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const {x, y} of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },

    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {boundingBox: {x, y, width, height}} = detectedCode

        ctx.lineWidth = 2
        ctx.strokeStyle = '#007bff'
        ctx.strokeRect(x, y, width, height)
      }
    },

    paintCenterText(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {boundingBox, rawValue} = detectedCode

        const centerX = boundingBox.x + boundingBox.width / 2
        const centerY = boundingBox.y + boundingBox.height / 2

        const fontSize = Math.max(12, 50 * boundingBox.width / ctx.canvas.width)
        console.log(boundingBox.width, ctx.canvas.width)

        ctx.font = `bold ${fontSize}px sans-serif`
        ctx.textAlign = "center"

        ctx.lineWidth = 3
        ctx.strokeStyle = '#35495e'
        ctx.strokeText(detectedCode.rawValue, centerX, centerY)

        ctx.fillStyle = '#5cb984'
        ctx.fillText(rawValue, centerX, centerY)
      }
    },

    logErrors(promise) {
      promise.catch(console.error)
    }
  }

}


</script>

<style scoped>

.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

</style>

